/* General Styles */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f6f9;
  color: #333;
}

/* Form Container */
.sub-t-form-container {
  max-width: 900px;
  margin: 30px auto;
  margin-top: -70px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.form-notes {
  font-size: 16px;
  background-color: #e6ffe6;
  padding: 15px;

  margin-bottom: 25px;
  line-height: 1.5;
}

/* Subject Category */
.subject-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.subject-category {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}

.subject-category h3 {
  margin-bottom: 15px;
  font-size: 20px;
  color: #006633;
  border-bottom: 2px solid #d9f2d9;
  padding-bottom: 5px;
}

.subject-option {
  display: flex;
  align-items: center; /* Vertically align items */
  margin-bottom: 10px;
}

.subject-option input[type="checkbox"] {
  margin: 0 10px 0 0; /* Add space only to the right of the checkbox */
  width: 16px;
  height: 16px; /* Ensures consistent checkbox size */
}

.subject-label {
  font-size: 16px;
  color: #555;
  line-height: 1.4; /* Ensures label text aligns with the checkbox */
  flex: 1; /* Ensures text takes the remaining horizontal space */
}

/* Buttons */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

/* .back-button,
.submit-button {
  background-color: #006633;
  color: #ffffff;
  border: none;
  padding: 12px 25px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
} */

.back-button:hover,
.submit-button:hover {
  background-color: #004d26;
}

/* Footer */
.form-footer {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #777;
}

.subject-container,
.subject-category language-subjects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sub-form-title {
  text-align: center;
  margin-bottom: 90px;
  font-size: 3rem;
  margin-top: 30px;
}

@media (max-width: 480px) {
  .form-notes {
    font-size: 16px;
    background-color: #e6ffe6;
    padding: 15px;

    margin-bottom: 25px;
    line-height: 1.5;
    width: 100%;
  }

  .subject-option {
    display: flex;
    align-items: center; /* Vertically align items */
    margin-bottom: 10px;
  }

  .subject-option input[type="checkbox"] {
    margin: 0 10px 0 0; /* Add space only to the right of the checkbox */
    width: 16px;
    height: 16px; /* Ensures consistent checkbox size */
  }

  .subject-label {
    font-size: 16px;
    color: #555;
    line-height: 1.4; /* Ensures label text aligns with the checkbox */
    flex: 1; /* Ensures text takes the remaining horizontal space */
  }

  .sub-form-title h1 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.7rem;
  }
  .button-container {
    display: flex;
    justify-content: flex-start; /* Align buttons in a row, starting from the left */
    gap: 10px; /* Adds space between buttons */
    margin-top: 30px; /* Adjust margin for spacing from the top */
  }

  .back-button,
  .submit-button {
    font-size: 14px; /* Adjust font size for buttons */
    padding: 8px 16px; /* Adjust padding for the buttons */
    width: auto; /* Set button width to auto so they fit content */
    text-align: center; /* Center align text inside the buttons */
  }
  .subject-category {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  }
  .sub-form-title {
    text-align: center;
    margin-bottom: 90px;
    font-size: 1.7rem;
    margin-top: 30px;
  }
}
