/* General Reset */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
  overflow-x: hidden;
}

/* Header */
.section-header {
  margin-bottom: 20px;
  background-color: #d4edda; /* Slightly darker green */
  padding: 10px;
  border-radius: 4px;
}

.section-header h2 {
  margin: 0;
  font-size: 1.2em;
  color: #155724; /* Dark green text */
}

/* Form Container */
.doc-form-container {
  max-width: 800px;
  margin: 20px auto;
  margin-top: -10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.doc-title {
  margin-top: 30px;
  font-size: 3rem;
  text-align: center;
}

.student-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.student-photo {
  max-width: 120px;
  height: auto;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.student-info label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.student-info input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
}

/* Document Upload Section */
.education-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.doc-row {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.doc-info {
  flex: 1;
}

.doc-info input[type="text"],
.doc-info input[type="file"],
.doc-upload input[type="file"] {
  width: 97%;
  height: 20px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f5f5f5;
}

.doc-upload span {
  display: block;
  margin-top: 5px;
  font-size: 0.9em;
  color: #555;
}

/* Buttons */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.next-button {
  font-size: 14px; /* Adjust font size for buttons */
  padding: 8px 16px; /* Adjust padding for the buttons */
  width: auto; /* Set button width to auto so they fit content */
  text-align: center; /* Center align text inside the buttons */
}
.back-button {
  background-color: #2196f3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 4px;
}

.back-button:hover,
.next-button:hover {
  background-color: #1976d2;
}

/* Footer */
.form-footer {
  text-align: center;
  margin-top: 20px;

  font-size: 0.9em;
  color: #999;
}

/* Responsive Design */
@media (max-width: 768px) {
  .doc-form-container {
    margin-top: 100px;
    padding: 15px;
  }

  .student-details {
    flex-direction: column;
  }

  .doc-row {
    flex-direction: column;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }
  .section-header {
    margin-bottom: 20px;
    background-color: #d4edda; /* Slightly darker green */
    padding: 10px;
    border-radius: 4px;
    width: 90%;
  }
  .form-footer {
    text-align: center;
    margin-top: 20px;
    width: 93%;
    font-size: 0.9em;
    color: #999;
  }
}

@media (max-width: 480px) {
  .form-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-right {
    margin-top: 10px;
  }

  .doc-title {
    margin-top: 20px;
    font-size: 1.7rem;
  }

  .back-button,
  .next-button {
    font-size: 0.9em;
    padding: 8px 15px;
  }
  .section-header {
    margin-bottom: 20px;
    background-color: #d4edda; /* Slightly darker green */
    padding: 10px;
    border-radius: 4px;
    width: 95%;
  }
  .doc-form-container {
    margin-top: 20px;
    max-width: 800px;
  }
  .form-footer {
    text-align: center;
    margin-top: 20px;
    width: 95%;
    font-size: 0.9em;
    color: #999;
  }
  .education-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  input[type="text"],
  input[type="file"] {
    width: 300px; /* Slightly smaller width for padding */
    margin: 0 auto; /* Center alignment if needed */
  }
  /* Default layout for larger screens */
  .student-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}
/* General Styles */
.education-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.doc-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-start;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.doc-info,
.doc-upload {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.doc-info label,
.doc-upload label {
  font-weight: bold;
  font-size: 0.9rem;
}

.doc-info input[type="text"],
.doc-info input[type="file"],
.doc-upload input[type="file"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 0.9rem;
}

.file-name {
  margin-top: 5px;
  font-size: 0.85rem;
  color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .doc-group {
    flex-direction: column;
    padding: 10px;
  }

  .doc-info,
  .doc-upload {
    flex: none;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .doc-group {
    padding: 8px;
    gap: 10px;
  }

  .doc-info input[type="text"],
  .doc-info input[type="file"],
  .doc-upload input[type="file"] {
    font-size: 0.85rem;
    padding: 6px;
    width: 100%;
    height: 40px;
  }

  .file-name {
    font-size: 0.8rem;
  }
}
