.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.logo {
  width: 200px;
  height: start;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1;
  position: relative;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
}

.logo img {
  height: 50px; /* Adjust the size of the logo */
}

.form-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px; /* Adjust size as needed */
  text-align: center;
}

.header-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Wrap content for smaller screens */
  margin-left: auto; /* Push items to the right */
}
