.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the container takes the full height */
  background: linear-gradient(
    135deg,
    #4caf50 0%,
    #66bb6a 100%
  ); /* Gradient background */
  position: relative;
}

.success-card {
  background-color: #fff; /* White background for the card */
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 3rem; /* Increased padding for a larger container */
  text-align: center;
  max-width: 500px; /* Increased max-width for a larger container */
  width: 100%;
  position: relative;
}

.success-icon {
  font-size: 4rem; /* Increased icon size */
  color: #43cea2; /* Color that matches the background */
  margin-bottom: 1.5rem;
}

.success-message {
  font-size: 2rem;
  color: #333;
  margin: 0.5rem 0;
}

.success-submessage {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 2rem;
}

.home-button {
  background-color: #43cea2;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #185a9d;
}

.footer-text {
  font-size: 0.85rem; /* Decreased font size slightly */
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.copyright-footer {
  width: 100%;
  text-align: center;
  padding: 0.4rem; /* Reduced padding for a thinner footer */
  background-color: #f8f8f8;
  color: #333;
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0;
  font-size: 0.85rem; /* Decreased font size slightly */
}
