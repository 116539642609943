.login-container {
  display: flex;
  height: 100vh;
  background-color: #0a5508; /* Entire background will be green */
  color: white;
  font-family: Arial, sans-serif;
}

.left-section {
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white; /* Text color remains white */
}

.left-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.left-section p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 40px;
}

.home-button:hover {
  background-color: #004ecb;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* This adds a green overlay to the right section */
}

.login-box {
  background-color: white;
  color: black;
  padding: 40px;
  width: 400px;
  height: 450px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.login-box:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
}

.login-box h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #0a5508;
}

.login-box form {
  display: flex;
  flex-direction: column;
}

.login-box input {
  margin-bottom: 20px;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out;
}

.login-box input:focus {
  border-color: #0a5508;
}

.login-box button {
  padding: 15px;
  font-size: 1rem;
  background-color: #0a5508;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.login-box button:hover {
  background-color: #083b06;
  transform: translateY(-3px);
}
