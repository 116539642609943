/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.login-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-image: image-set("./images/bg_image.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.login-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 77, 64, 0.8); /* Overlay color */
  z-index: 1;
}

.left-section,
.right-section {
  position: relative;
  z-index: 2;
}

.left-section {
  flex: 1;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.left-section h1 {
  font-size: 2.5rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.left-section p {
  display: none;
}

.logo img {
  justify-content: center;
  max-width: 150px;
  margin-bottom: 20px;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}

.login-box {
  background-color: white;
  padding: 30px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  height: 250px;
  text-align: center;
}

.login-box h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.login-box input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.login-box .button {
  width: 100%;
  padding: 10px;
  background-color: #004d40;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-box .button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .login-container {
    flex-direction: column;
  }

  .left-section {
    flex: none;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .left-section {
    padding: 10px;
  }

  .left-section h1 {
    font-size: 2rem;
  }

  .left-section p {
    font-size: 0.9rem;
  }

  .logo img {
    max-width: 120px;
  }

  .login-box {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .login-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left-section {
    text-align: center;
  }

  .left-section h1 {
    font-size: 2rem;
  }

  .left-section p {
    display: none;
  }

  .login-box {
    background-color: white;
    padding: 30px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 10%;
    max-width: 400px;
    height: 60%;
    text-align: center;
  }

  .logo {
    margin-top: 25px;
    max-width: 100px;
  }

  .login-box {
    margin-top: -75px;
    width: 90%;
    padding: 15px;
    height: auto;
  }

  .login-box input[type="text"] {
    width: 100%;
    padding: 15px;
  }

  .user-info {
    display: flex;
    justify-content: center;
  }
  .custom-button {
    margin-top: 20px;
  }
}
