/* Start of doc.css */
.form-container {
  width: 90%;
  height: auto;
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f0f9f5; /* Light green background */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.logo {
  width: 200px;
  height: start;
}

.student-details {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 20px; /* Added gap between elements */
}

.student-photo {
  width: 120px;
  height: 140px;
  border-radius: 6px;
  object-fit: cover;
}

.student-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; /* Increased gap between fields */
  width: 100%;
}

.student-info label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.student-info input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #e9ecef;
}

.education-details {
  margin-bottom: 20px;
}

.doc-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Increased margin for more space between rows */
  gap: 20px; /* Added gap between elements in a row */
}

.doc-info {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.doc-upload {
  display: flex;
  align-items: center;
  gap: 10px; /* Added gap between file input and file name */
  justify-content: space-between; /* Ensure fields are spaced evenly */
}

.doc-upload input[type="file"] {
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.doc-upload span {
  font-size: 0.9em;
  color: #6c757d;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #0d6efd;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button {
  width: 80px;
  margin-top: 20px;
}

.back-button:hover {
  background-color: #5c636a;
}

.back-button:container {
  display: flex;
  width: 80px;
  padding: 20px;
}

.student-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px; /* Increased gap between fields */
  width: 75%;
  padding-right: 20px; /* Added padding to shift fields to the right */
}

/* End of doc.css */

/* Start of form.css */
/* src/components/form.css */
.form-container {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  align-items: flex-start; /* Aligns all columns at the top */
}

.details-column {
  flex: 1;
  min-width: 300px;
  padding: 20px;
  background-color: #e6ffe6;
  border-radius: 10px;
}

.details-column input,
select {
  font-size: 14px;
}

.details-column h3 {
  background-color: #d1e7dd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.name-input {
  display: flex;
  gap: 10px;
}

.name-input select {
  width: 20%;
}

.name-input input {
  width: 80%;
}

.address-input input {
  width: 95.5%;
  padding-bottom: 90px;
}

.logo-section {
  position: absolute;
  top: -10px;
  left: 90px;
  width: 280px; /* Remove fixed width to avoid stretching */
  height: auto;
}

.logo-section img {
  width: 100%; /* This ensures the image fills the container's width */
  height: auto; /* Maintains the aspect ratio */
  max-width: 100%; /* Ensures the image doesn't exceed the container's width */
  display: block; /* Removes any bottom space under the image */
  margin-top: 1px;
}

.user-info {
  display: flex;
  flex-direction: column; /* Stack welcome-text and admissions-count vertically */
  text-align: left; /* Align text to the right for a clean look */
  margin-right: 20px; /* Add some space between user-info and buttons */
}

.photo-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #e6ffe6;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.photo-section input[type="file"] {
  width: auto; /* Makes the input box fit its content */
  max-width: 200px; /* Optionally limit the maximum width */
  padding: 5px; /* Add some padding for a nicer look */
  margin-top: 10px; /* Add a little space between the input and the label or other content */
  font-size: 14px; /* Adjust font size as needed */
  border: 1px solid #ccc; /* Optional: add a border for a polished look */
  border-radius: 5px; /* Optional: rounded corners for consistency */
}

.photo-section label {
  margin-bottom: 10px;
  font-weight: bold;
}

.photo-section img {
  margin-top: 10px;
  max-width: 150px;
  max-height: 150px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #ccc;
}

.photo-section p {
  margin-top: 10px;
  color: red;
  font-size: 12px;
}

.details-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.left-column,
.right-column {
  flex: 1;
  min-width: 300px;
}

label {
  align-self: start;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
}

input,
textarea {
  width: 95.5%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

select {
  align-items: center;
  width: 35%;
  padding: 10px;
  background-color: white;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.education-section {
  padding: 20px;
  background-color: #e6ffe6;
  border-radius: 10px;
  margin-top: 20px;
}

.qualification-row {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  align-items: center; /* Ensures all input fields in a row are aligned */
}

.qualification-row label {
  width: 100px;
}

.qualification-row input {
  width: calc(100% - 100px);
  font-size: 14px;
}

.reset-button {
  font-size: 14px; /* Adjust font size for buttons */
  padding: 8px 16px; /* Adjust padding for the buttons */
  width: auto; /* Set button width to auto so they fit content */
  text-align: center; /* Center align text inside the buttons */
}

.reset-button:hover {
  background-color: #e23d14;
}

.reset-button:container {
  display: flex;
  width: 80px;
  padding: 20px;
}

.submit-button {
  width: 90px;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #7ae74f;
}

.submit-button:container {
  display: flex;
  width: 90px;
  padding: 20px;
}

.next-button {
  width: 80px;
  margin-top: 20px;
}

.next-button:hover {
  background-color: #7ae74f;
}

.next-button:container {
  display: flex;
  width: 80px;
  padding: 20px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f1f1f1;
  position: relative;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
}

.logo img {
  height: 50px; /* Adjust the size of the logo */
}

.form-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px; /* Adjust size as needed */
  text-align: center;
}

.header-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Wrap content for smaller screens */
  margin-left: auto; /* Push items to the right */
}

.welcome-text {
  margin-right: 15px;
}

.admissions-count {
  margin-right: 25px;
}

.custom-button {
  margin-right: 10px;
}

.header-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; /* Add spacing for smaller screens */
  z-index: 1000;
}

.logout-button:hover {
  background-color: #0056b3; /* Darker color on hover */
  transform: scale(1.05); /* Slightly increase the size on hover */
}
@media (max-width: 768px) {
  .form-header {
    flex-direction: row; /* Keep items in a row */
    justify-content: space-between; /* Space between the logo and logout button */
    align-items: center; /* Align items vertically */
  }

  .logo {
    text-align: left; /* Align logo to the left */
    margin-bottom: 0; /* Remove extra space */
  }
}

@media (max-width: 480px) {
  .header-button {
    margin-top: 45px; /* Remove any top margin */
    align-self: flex-start; /* Align logout button to the right */
    padding: 6px 12px; /* Adjust button padding for smaller screens */
  }
  .admissions-count {
    margin-right: 15px;
  }
}

.form-header h1 {
  margin-top: 220px;
}

.form-footer {
  flex-shrink: 0;
  text-align: center;
  padding: 10px;
  background-color: #f1f1f1;
  position: relative;
  width: 98%;
  bottom: 0;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content form label {
  margin-bottom: 10px;
}

.modal-content form button {
  margin-top: 15px;
}

/* End of form.css */

/* Start of sub12.css */
.form-container {
  position: relative;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logout-button {
  color: white;
  border: 1px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  height: 40px;
}

.logout-button:hover {
  background-color: #ff3333;
}

.bosse-logo {
  width: 115px; /* Adjust this value to get the perfect size */
  height: auto;
  margin-right: 20px; /* Space between logo and title */
}

.form-title {
  color: #333;
  font-size: 1.8em;
  margin: 0;
}

.form-notes {
  font-size: 0.9em;
  color: #555;
  background-color: #e7e7e7;
  padding: 15px;
  margin-top: -1px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.subject-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: auto;
}

.subject-category {
  width: 30%;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subject-category h3 {
  color: #444;
  font-size: 1.2em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.subject-option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-align: left;
}

.subject-option input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.subject-label {
  cursor: pointer;
  color: #333;
  font-size: 1em;
  display: inline-block;
  width: calc(100% - 30px); /* Ensures label aligns after checkbox */
}

.subject-option input[type="checkbox"]:disabled + .subject-label {
  color: #aaa;
  cursor: not-allowed;
}

.language-subjects {
  background-color: #ffffff;
  text-align: center;
}

.non-language-subjects {
  background-color: #ffffff;
  text-align: center;
}

.vocational-subjects {
  background-color: #ffffff;
  text-align: center;
}

/* End of sub12.css */

/* Start of sub10.css */
.form-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.bosse-logo {
  width: 115px; /* Adjust this value to get the perfect size */
  height: auto;
  margin-right: 20px; /* Space between logo and title */
}

.form-title {
  color: #333;
  font-size: 1.8em;
  margin: 0;
}

.form-notes {
  font-size: 0.9em;
  color: #555;
  background-color: #e7e7e7;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.subject-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.subject-category {
  width: 30%;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subject-category h3 {
  color: #444;
  font-size: 1.2em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.subject-option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.subject-option input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

.subject-label {
  cursor: pointer;
  color: #333;
  font-size: 1em;
  display: inline-block;
  width: calc(100% - 30px); /* Ensures label aligns after checkbox */
}

.subject-option input[type="checkbox"]:disabled + .subject-label {
  color: #aaa;
  cursor: not-allowed;
}

.language-subjects {
  background-color: #ffffff;
  text-align: center;
}

.non-language-subjects {
  background-color: #ffffff;
  text-align: center;
}

.vocational-subjects {
  background-color: #ffffff;
  text-align: center;
}

/* End of sub10.css */

.home-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #0067ff;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.home-button:hover {
  background-color: #004ecb;
}

/* End of login.css */

.input-group {
  display: flex;
  flex-direction: column;
  width: 250px; /* Increased width */
}

.input-group input,
.input-group select {
  height: 39px; /* Slightly increased height */
  padding: 10px;
  font-size: 14px; /* Increased font size */
  box-sizing: border-box;
  border: 1px solid #ccc; /* Light border for better visibility */
  border-radius: 4px; /* Rounded corners for a softer look */
  width: 100%;
}

.qualification-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px; /* Increased margin to reduce emptiness */
}

.add-subject-button {
  color: white;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  justify-self: end;
  align-self: end;
  font-size: 14px; /* Larger font size */
  border-radius: 4px; /* Rounded corners */
}

.subject-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Added space between form and table */
}

.subject-list th,
.subject-list td {
  padding: 10px; /* Increased padding */
  text-align: left;
  border: 1px solid #ddd;
  font-size: 14px; /* Increased font size */
}

.subject-list tr:nth-child(even) {
  background-color: #f9f9f9;
}

.subject-list tr:hover {
  background-color: #f1f1f1;
}

@media (max-width: 480px) {
  /* General Reset */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }

  /* Body Styling */
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }

  form {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }

  form h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #444;
  }

  /* Form Group Styling */
  .form-group {
    margin-bottom: 15px;
  }

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
  }

  input:focus,
  select:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
    background: #fff;
  }

  /* Button Styling */
  button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #0056b3;
  }

  /* Error and Success Messages */
  .error-message {
    color: #d9534f;
    font-size: 0.9rem;
    margin-top: 5px;
  }

  .success-message {
    color: #5cb85c;
    font-size: 0.9rem;
    margin-top: 5px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    form {
      padding: 15px;
    }

    form h1 {
      font-size: 1.2rem;
    }
  }

  /* Optional: Customizing Placeholder Text */
  input::placeholder,
  textarea::placeholder {
    color: #aaa;
    font-style: italic;
  }

  .qualification-row {
    display: block;
    gap: 10px;
    margin-top: 20px;
    align-items: center; /* Ensures all input fields in a row are aligned */
  }

  .qualification-row label {
    width: 100px;
  }

  .qualification-row input {
    width: calc(100% - 100px);
    font-size: 14px;
  }
}

/* General styling */
.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f9f9f9; /* Light background */
  border: 1px solid #e0e0e0; /* Optional border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 100%;
}

/* Styling for welcome section */
.welcome-section {
  display: flex;
  flex-direction: column; /* Stack welcome and admission text */
  align-items: flex-start; /* Align to the left by default */
}

.welcome-text {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* Dark text */
}

.admissions-count {
  font-size: 14px;
  color: #666; /* Gray text */
}

/* Button styling */

.custom-button {
  background-color: #007bff; /* Blue button */
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 10px;
  height: 35px;
  /* Ensure alignment */
  margin-left: 10px; /* Adds space between Logout and Register buttons */
}

.custom-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Responsive styles for tablets and smaller devices */
@media (max-width: 768px) {
  .user-info {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center all items */
  }

  .welcome-section {
    align-items: center; /* Center align text in mobile view */
  }

  .custom-button {
    margin-left: 0;
    align-self: center; /* Center button */
    margin-top: 10px; /* Add space above the button */
    width: 100%; /* Optional: Make the button full-width */
    text-align: center;
  }
  .form-header h1 {
    margin-top: 350px;
    font-size: 2.2rem;
  }
}

/* Responsive styles for small devices (mobile phones) */
@media (max-width: 480px) {
  .logo-section img {
    width: 450px; /* This ensures the image fills the container's width */
    height: 100px; /* Maintains the aspect ratio */
    max-width: 100%; /* Ensures the image doesn't exceed the container's width */
    display: block; /* Removes any bottom space under the image */
    margin-top: 1px;
    margin-left: -80px;
  }
  .welcome-text {
    font-size: 16px; /* Smaller font for smaller screens */
    text-align: center; /* Center align text */
  }

  .admissions-count {
    font-size: 12px; /* Adjust font size */
    text-align: center; /* Center align text */
  }

  .header-right {
    margin-left: 95px;
  }

  .header-button {
    font-size: 10px; /* Smaller font size */
    padding: 4px 8px; /* Reduced padding */
    width: 100px; /* Smaller width */
    height: 30px;
    text-align: center; /* Center align text inside buttons */
    margin-top: -5px;
    margin-right: 30px;
  }

  .custom-button {
    font-size: 10px; /* Smaller font size */
    padding: 4px 8px; /* Reduced padding */
    width: 100px; /* Smaller width */
    height: 30px;
    text-align: center; /* Center align text inside buttons */
    margin-top: 45px;
    margin-left: 5px;
  }
}

/* @media (min-width: 768px) {
  .form-header h1 {
    margin-top: 400px;
    font-size: 2rem;
  }
} */

@media (min-width: 200px) and (max-width: 700px) {
  .form-header h1 {
    margin-top: 180px;
    font-size: 1.5rem;
  }
}
.head-title {
  margin-top: 30px;
  font-size: 3rem;
  text-align: center;
}
@media (max-width: 480px) {
  .head-title {
    margin-top: 30px;
    font-size: 1.8rem;
    text-align: center;
  }
}
.form-containers {
  margin-top: -50px;
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f0f9f5; /* Light green background */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.r-custom-button {
  font-size: 10px; /* Smaller font size */
  padding: 4px 8px; /* Reduced padding */
  width: 200px; /* Smaller width */
  height: 40px;
  text-align: center; /* Center align text inside buttons */

  margin: 0 auto; /* Centers the button */
  margin-top: 15px;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .head-title {
    margin-top: 30px;
    font-size: 1.8rem;
    text-align: center;
  }
  .custom-button {
    margin-top: -35px;
    width: 50%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    margin-left: 90px;
  }
  .form-containers {
    width: 90%;
  }
}
@media (min-width: 375px) and (max-width: 667px) {
  .logo-section img {
    width: 450px;
    height: 100px;
    max-width: 100%;
    display: block;
    margin-top: 1px;
    margin-left: -90px;
  }
  .header-button {
    font-size: 10px;
    padding: 4px 8px;
    width: 100px;
    height: 30px;
    text-align: center;
    margin-top: -5px;
    margin-right: 20px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input {
  width: 95%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.show-password-container {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically align the checkbox and label */
  gap: 4px; /* Minimal gap between checkbox and text */
}

.show-password-container input[type="checkbox"] {
  margin: 0; /* Remove browser-specific margins */
  padding: 0; /* Remove any padding */
  vertical-align: middle; /* Align checkbox with the middle of the text */
  width: 16px; /* Set explicit width */
  height: 16px; /* Set explicit height */
}

.show-password-container label {
  margin: 0; /* Remove default label margin */
  padding: 0;
  font-size: 14px; /* Adjust font size for better alignment */
  line-height: 1.2; /* Ensure label text alignment with the checkbox */
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.error {
  margin-top: 10px;
  color: red;
  font-size: 14px;
}
